<template>
  <div>
    <back></back>
    <div class='user'>
      <div class='globle_border' v-loading="loading">

        <div class="search">
          <el-select v-model="startStatus" placeholder="请选择" @change="onSearch" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="tendersName" placeholder="请输入标书名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-button type="primary" @click="handleAdd">一键评标</el-button>
        </div>
        <div class="table_list globle_table">
          <el-table :data="tableData" style="width: 100%;" max-height="540">
            <el-table-column prop="productName" label="名称"></el-table-column>
            <el-table-column prop="createTime" label="投标时间"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span :class="scope.row.status == 1 ? 'green' : 'gray'">{{ rowstatus(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" header-align="center" align="center">

              <template slot-scope="scope">
                <el-button v-if="scope.row.status == 1" class="btn" type="primary" plain @click="handleView(scope.row)">评标</el-button>
                <!-- <el-button class="btn" type="primary" plain @click="handleView(scope.row)">查看</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
      </el-pagination>

      <el-dialog title="一键评标" :visible.sync="dialogVisible" :before-close="onCancel">
        <el-form label-width="180px" class="demo-ruleForm" ref="form" @submit.prevent="onSubmit">
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择评标规则">
                <el-select v-model="value" filterable placeholder="请选择">
                  <el-option v-for="item in lists" :key="item.tendersAutomaticId" :label="item.name" :value="item.tendersAutomaticId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <span slot="footer">
          <el-button @click="onCancel">取 消</el-button>
          <el-button type="primary" @click="submitForm" :loading="submitLoding">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>

</template>

<script>
import back from '../../../components/back.vue'
export default {
  components: { back },
  data () {
    return {
      content: "",
      loading: false,
      submitLoding: false,
      tendersName: '',//标书名称
      tendersNumber: '',//标号
      tableData: [],
      lists: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      tendersId: '',
      departmentList: [
        {
          departmentId: 1,
          departmentName: '商超'
        }
      ],
      ruleForm: {
        tendersNumber: '',
      },
      roleList: [],
      startStatus: '',
      options: [{
        value: '1',
        label: '未评标'
      }, {
        value: '2',
        label: '已评标'
      },],
      options1: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  computed: {
    rowstatus () {
      return function (row) {
        return this.options.find(e => e.value == row.status).label
      }
    },
  },
  created () {
    this.tendersId = this.$route.query.id

    this.getportalPageList()
  },

  methods: {
    // 招标列表
    getportalPageList () {
      this.loading = true
      this.$axios.get(this.$api.list, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          status: this.startStatus,
          productName: this.tendersName,
          tendersId: this.tendersId
        }
      }).then((res) => {
        this.tableData = res.data.result.list
        this.totalItemsCount = res.data.result.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    // 搜索
    onSearch () {
      this.getportalPageList()
    },
    // 新增（对话框）
    handleAdd () {
      this.isAdd = true
      this.dialogVisible = true
      this.list()
    },
    list () {
      this.$axios.get(this.$api.tenders_automatic_list, {
        params: {
          page: this.currentPage,
          pageSize: 999,
        }
      }).then(res => {
        this.lists = res.data.result.list
      })
    },

    // 提交
    submitForm () {
      if (this.value == "" || this.tableData == null) {
        return this.$message.error('请选择评标规则或列表无数据不能提交！');
      }
      this.$axios.post(this.$api.one_evaluation, {
        tendersAutomaticId: this.value,
        tendersId: this.tendersId
      }).then((res) => {
        if (res.data.code == 100) {
          this.$message.success(res.data.desc);
        }
        this.dialogVisible = false
        this.value = ''
        this.getportalPageList()
      }).catch((err) => {
        this.$message.error(err.data.desc);
      });

    },

    // 查看
    handleView (row) {
      this.$router.push({ path: '/biddingManagement/detail/bidEvaluationTable', query: { id: row.tendersId, productId: row.productId, productName: row.productName }, });
    },

    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e
      this.getportalPageList()
    },
    // 换页
    handleCurrentChange () {
      this.page += 1
      this.getportalPageList()
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped lang='scss'>
.user {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;
      margin-left: 20px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .table_list {
    width: 100%;
    max-width: 1560px;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #EB2500;
    }

    .green {
      color: #069BBC;
    }

    .gray {
      color: #68706E;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #F4F6F9;
    }
  }

}
</style>
